import {graphql, Link} from "gatsby";
import React from "react";

import IsoCar1 from "../../../assets/img/about/about1.png";
import IsoCar2 from "../../../assets/img/about/about2.png";
import IsoCar3 from "../../../assets/img/about/about3.png";
import IsoCar4 from "../../../assets/img/about/about4.png";
import IsoCar5 from "../../../assets/img/about/about5.png";
import IsoCar6 from "../../../assets/img/about/about6.png";

import showdown from 'showdown'

const converter = new showdown.Converter()

const AboutHeaderComponent = ({image, title, subtitle, description, alert}) => {
    return (
        <>
            <header className="pt-8">
                <div className="page-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4 my-auto text-center text-xl-start">
                                <h1 className="display-1 text-gradient text-primary mb-0">{title}</h1>
                                <h2 className="display-4 mb-4">{subtitle}</h2>
                                <p className="lead" dangerouslySetInnerHTML={{__html: converter.makeHtml(description)}}/>
                                <div className="buttons">
                                    <Link to={'/contact'} className="btn bg-gradient-primary mt-4">Demander un devis</Link>
                                    <Link to={'/nos-missions'} type="button" className="btn text-primary shadow-none mt-4">Nos missions</Link>
                                </div>
                            </div>
                            <div className="col-xl-8 ps-5 pe-0 pb-md-0 pb-0 d-none d-xl-block">
                                <div className="row">
                                    <div className="col-lg-3 col-6">
                                        <img className="w-100 border-radius-lg shadow mt-7" src={IsoCar1} alt=""/>
                                    </div>
                                    <div className="col-lg-3 col-6">
                                        <img className="w-100 border-radius-lg shadow" src={IsoCar4} alt=""/>
                                            <img className="w-100 border-radius-lg shadow mt-4" src={IsoCar5} alt=""/>
                                    </div>
                                    <div className="col-lg-3 col-6">
                                        <img className="w-100 border-radius-lg shadow mt-0 mt-lg-5" src={IsoCar6} alt=""/>
                                            <img className="w-100 border-radius-lg shadow mt-4" src={IsoCar3} alt=""/>
                                    </div>
                                    <div className="col-lg-3 col-6">
                                        <img className="w-100 border-radius-lg shadow mt-7" src={IsoCar2} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {alert != null ? (
                <div className="col-12 mx-auto mt-4">
                    <div className="toast fade show p-2 mx-auto mb-2 w-75" role="alert" aria-live="assertive"
                         aria-atomic="true">
                        <div className="toast-body text-center">
                            <span className={'h3'}>{alert}</span>
                        </div>
                    </div>
                </div>
            ) : <></>}
        </>
    )
}

export default AboutHeaderComponent;

export const query = graphql`
  fragment HeaderFields on MarkdownRemarkFrontmatter {
    header {
      title
      subtitle
      description
      image
      alert
    }
  }
`;
