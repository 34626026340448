import * as React from "react"

import LayoutComponent from "../components/layout";
import {graphql} from "gatsby";
import MetadataComponent from "../components/fields/metadata";
import AboutHeaderComponent from "../components/fields/about/header";
import KeyNumberList from "../components/fields/KeyNumberList";
import SimpleContentComponent from "../components/fields/simpleContent";
import IconContentList from "../components/fields/about/iconContentList";
import ReviewsComponent from "../components/fields/reviews";
import ActivityComponent from "../components/fields/activity";
import {useEffect, useState} from "react";
import { client } from '../../tina/__generated__/client';
import CallToActionComponent from "../components/fields/callToAction";
import RealisationsComponent from "../components/realisations";

const QuiSommesNousPage = ({data}) => {
    const [realisations, setRealisations] = useState([]);
    const {frontmatter} = data.markdownRemark;

    useEffect(() => {
        async function retrieveRealisations() {
            let reals = await client.queries.clientsConnection();

            if (typeof reals.data.clientsConnection.edges !== 'undefined') {
                setRealisations(reals.data.clientsConnection.edges)
            }
        }

        if(realisations.length == 0) {
            retrieveRealisations();
        }

        console.log(realisations);
    })

    return (
        <>
            <MetadataComponent
                description={frontmatter.metadata.description}
                title={frontmatter.metadata.title}
                keywords={frontmatter.metadata.keywords}
                code={frontmatter.metadata.code}
            />

            <LayoutComponent activeCategory={"qui-sommes-nous"}>
                <AboutHeaderComponent
                    title={frontmatter.header.title}
                    subtitle={frontmatter.header.subtitle}
                    description={frontmatter.header.description}
                    image={frontmatter.header.image}
                    alert={frontmatter.header.alert}
                />

                <KeyNumberList keyNumbers={frontmatter.keyNumbers} />

                <section className="py-6">
                    <div className="container">
                        <SimpleContentComponent
                            title={frontmatter.simpleContent.title}
                            subtitle={frontmatter.simpleContent.subtitle}
                            description={frontmatter.simpleContent.description}
                        />

                        <IconContentList iconContents={frontmatter.iconContents}/>
                    </div>
                </section>

                <ReviewsComponent
                    title={frontmatter.userReviews.title}
                    subtitle={frontmatter.userReviews.subtitle}
                    reviews={frontmatter.userReviews.reviews}
                />

                <ActivityComponent
                    title={frontmatter.activity.title}
                    subtitle={frontmatter.activity.subtitle}
                    description={frontmatter.activity.description}
                    link={frontmatter.activity.link}
                    reference={frontmatter.activity.reference}
                    iconContents={frontmatter.activity.iconContents}
                />
                {/*<RealisationsComponent
                    title={frontmatter.realisations.title}
                    subtitle={frontmatter.realisations.subtitle}
                    description={frontmatter.realisations.description}
                    realisations={realisations}
                />*/}

                <RealisationsComponent nbRealisations={6}/>

                <CallToActionComponent
                    title={frontmatter.callToAction.title}
                    link={frontmatter.callToAction.link}
                    reference={frontmatter.callToAction.reference}
                    image={frontmatter.callToAction.image}
                />
            </LayoutComponent>
        </>
    );
}

export default QuiSommesNousPage;

export const pageQuery = graphql`
query {
  markdownRemark(frontmatter: {metadata: {code: {eq: "about"}}}) {
      frontmatter {
        ...MetadataFields
        ...HeaderFields
        ...KeyNumberFields
        ...SimpleContentFields
        ...IconContentFields
        ...ReviewsFields
        ...ActivityFields
        ...RealisationsFields
        ...CallToActionFields
      }
    
  }
}
`
