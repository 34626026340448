import React from "react";
import {graphql} from "gatsby";

const KeyNumberComponent = ({number, subtitle, description}) => {
    return (
        <div className="col-lg-3 mb-3 mb-lg-0">
            <span className="text-white display-3">{number}</span>
            <br/>
            <span className="display-6 mt-3 text-white">{subtitle}</span>
            <p>{description}</p>
        </div>
    )
}

export default KeyNumberComponent;

export const query = graphql`
  fragment KeyNumberFields on MarkdownRemarkFrontmatter {
    keyNumbers {
      number
      subtitle
      description
    }
  }
`;
