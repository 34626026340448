import {graphql, Link} from "gatsby";
import React from "react";
import WaveWhites from "../../assets/img/shapes/waves-white.svg";
import showdown from 'showdown';

const converter = new showdown.Converter();

const ReviewsComponent = ({reviews, title, subtitle}) => {
    return (
        <section className="py-6 position-relative overflow-hidden">
            <img src={WaveWhites} alt="pattern-lines" className="position-absolute opacity-6 top-0 w-100 z-index-1"/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-10 mx-auto bg-gradient-dark border-radius-lg p-4">
                        <div className="row">
                            <div className="offset-md-3  col-md-6">
                                <h2 className="display-5 mb-3 text-white text-center">{title}</h2>
                                <p className="display-7 font-weight-light text-white text-center">
                                    {subtitle}
                                </p>
                            </div>
                        </div>
                        <div className="row mt-lg-6 mt-4">
                            {reviews.map((review, index) => {
                                return (
                                    <div key={index} className="col-lg-3 col-md-6 mb-lg-0 mb-4 ms-auto">
                                        <div className="card">
                                            <div className="card-body text-center pb-0">
                                                <div className="text-center">
                                                    <div className="rating">
                                                        <span>⭐</span>
                                                        <span>⭐</span>
                                                        <span>⭐</span>
                                                        <span>⭐</span>
                                                        <span>⭐</span>
                                                    </div>
                                                </div>
                                                <p className="mt-4 pt-2" dangerouslySetInnerHTML={{__html: converter.makeHtml(review.quote)}}/>
                                            </div>
                                            <div className="card-footer text-center pt-3 position-relative z-index-1">
                                                <p className="h6 mb-0 mt-2">{review.name}</p>
                                                <p className="text-xs mb-0">{review.job}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
)
}

export default ReviewsComponent;

export const query = graphql`
  fragment ReviewsFields on MarkdownRemarkFrontmatter {
    userReviews {
      title
      subtitle
      reviews {
        name
        job
        quote
      }
    }
  }
`;
