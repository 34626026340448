import React from "react";
import WavySection from "../tools/wavySection";
import KeyNumberComponent from "./keyNumber";

const KeyNumberList = ({keyNumbers}) => {
    return (
        <WavySection>
            <div className="container pt-9 pb-7 pb-xl-9">
                <div className="row justify-content-center text-center text-white">
                    {keyNumbers.map((item, index) => {
                        return (
                            <KeyNumberComponent
                                key={index}
                                number={item.number}
                                subtitle={item.subtitle}
                                description={item.description}
                            />
                        );
                    })}
                </div>
            </div>
        </WavySection>
    )
}

export default KeyNumberList;
