import React from "react";
import IconContentComponent from "./iconContent";

const IconContentList = ({iconContents}) => {
    return (
        <div className="row">
            {iconContents.map((item, index) => <IconContentComponent key={index} icon={item.image} title={item.title} description={item.description}/>)}
        </div>
    )
}

export default IconContentList;
