import React from "react";
import SvgComponent from "../../tools/svg";
import {graphql} from "gatsby";
import showdown from 'showdown'

const converter = new showdown.Converter()

const IconContentComponent = ({icon, title, description}) => {
    return (
        <div className="col-lg-4 col-md-6">
            <div className="p-3 text-center">
                <div className="icon icon-shape bg-gradient-primary shadow mx-auto">
                    <SvgComponent url={icon}/>
                </div>
                <h4 className="display-7 mt-4">{title}</h4>
                <p dangerouslySetInnerHTML={{__html: converter.makeHtml(description)}}/>
            </div>
        </div>
    )
}

export default IconContentComponent;

export const query = graphql`
  fragment IconContentFields on MarkdownRemarkFrontmatter {
    iconContents {
      image
      title
      description
    }
  }
`;
