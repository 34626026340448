import {graphql} from "gatsby";
import React from "react";
import showdown from 'showdown'

const converter = new showdown.Converter()

const SimpleContentComponent = ({title, subtitle, description}) => {
    return (
        <div className="row">
            <div className="col-lg-8 mx-auto text-center pb-4">
                <h3 className="display-4 text-gradient text-primary">{subtitle}</h3>
                <h2 className={"display-1"}>{title}</h2>
                <div className="lead" dangerouslySetInnerHTML={{__html: converter.makeHtml(description)}}/>
            </div>
        </div>
    )
}

export default SimpleContentComponent;

export const query = graphql`
  fragment SimpleContentFields on MarkdownRemarkFrontmatter {
    simpleContent {
      title
      subtitle
      description
    }
  }
`;
